@import '~@ri-digital/web-style-guide/index.scss';
@import '../FullCheckoutSignUpForm/FullCheckoutMixins.scss';

@include checkoutSignUpPage;

.pagewrapper {
  margin: 0 auto;
  width: 95%;
  max-width: 366px;
  margin-top: 64px;
  position: relative;

  @include breakpoint(large) {
    width: 420px;
    margin-top: 124px;
  }

  .heading-container {
    text-align: center;
    margin: 0 0 16px 0;

    @include breakpoint(large) {
      margin: 0 0 40px 0;
    }
  }

  &__heading {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: $dark-grey;
    margin: 0;
    text-transform: uppercase;
  }
}
