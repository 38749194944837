@mixin checkoutSignUpPage {
  .checkout-signup-page {
    width: 300px;
    @include breakpoint(large) {
      width: 369px;
    }

    .pagewrapper__content {
      background: $white;
      box-shadow: none;
      padding: 0;
    }

    input {
      border: 1px solid $border;

      &:focus {
        border: 1px solid $black;
      }
    }

    :global(.date-of-birth-inputs) {
      & > div:nth-child(1) {
        font-size: 16px;
        padding-left: 5px;
      }

      select {
        width: 100%;
        border: 1px solid $border;
      }
    }
  }
}
