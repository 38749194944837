@import '~@ri-digital/web-style-guide/index.scss';
@import '../../styles/variables.scss';

.login-footer {
  text-align: center;
  margin-top: 64px;

  p {
    font-family: $baseFontFamily;
    font-size: 12px;
    line-height: 16px;
    color: $placeholder-grey;
  }
}
