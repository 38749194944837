@import '~@ri-digital/web-style-guide/index.scss';

.login-header {
  text-align: center;
  padding: 17px 0 13px 0;
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;

  &__shadow {
    box-shadow: 0 2px 24px 2px rgba(0, 0, 0, 0.1);
  }

  &__transparent {
    background: none;
  }
}

.ri-logo {
  height: 22px;
  max-width: 160px;
}

.ri-logo-overlay {
  height: 29px;
}
