@import '~@ri-digital/web-style-guide/index.scss';
@import './styles/font.scss';

* {
  font-family: $baseFontFamily;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.route-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
