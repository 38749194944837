@import '~@ri-digital/web-style-guide/index.scss';

.login-header {
  text-align: center;
  z-index: 2;
  background: $white;
  padding: 27px 24px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  text-decoration: none;
  color: $onyxBlack;

  @include breakpoint(large) {
    padding: 27px 42px;
    text-align: left;
  }

  &__transparent {
    padding: 16 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: auto;
    background: none;
    flex-direction: column-reverse;
    gap: 44px;

    @include breakpoint(large) {
      padding: 0 42px;
      flex-direction: row;
      height: 80px;
      gap: 0;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
  }
}

.ri-logo {
  height: 22px;
  max-width: 125px;
}

.ri-logo-overlay {
  height: 29px;
  max-width: 160px;

  @include breakpoint(large) {
    height: 22px;
    max-width: 125px;
  }
}

.close-icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: end;

  @include breakpoint(large) {
    padding-bottom: 0;
  }
}

.close-icon {
  cursor: pointer;
  background-color: $whiteSmoke;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 28px;
  height: 28px;

  @include breakpoint(large) {
    width: 64px;
    height: 35px;
    border-radius: 2px;
    border: solid 1px $concreteGray;
    color: $onyxBlack;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
  }

  svg {
    width: 12px;
    @include breakpoint(large) {
      width: 15px;
    }
  }
}

.icon-text {
  display: none;
  @include breakpoint(large) {
    display: block;
    margin-left: 8px;
    font-size: 12px;
    text-decoration: none;
  }
}

.back-icon-wrapper {
  position: absolute;
  left: 24px;
  top: 24px;

  & > a {
    text-decoration: none;
  }

  @include breakpoint(large) {
    left: auto;
    right: 42px;
  }
}

.back-icon {
  cursor: pointer;
  background-color: $whiteSmoke;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  border: none;
  width: 28px;
  height: 28px;

  @include breakpoint(large) {
    width: 70px;
    height: 35px;
    border-radius: 2px;
    border: solid 1px $concreteGray;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 12px;
    @include breakpoint(large) {
      width: 15px;
    }
  }
}
