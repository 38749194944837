@import '~@ri-digital/web-style-guide/index.scss';

.title {
  color: $black;
  font-family: sang-bleu, union, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.content {
  margin: 24px 0;
  color: $black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.button {
  border-radius: 4px;
  border: 0.5px solid $concreteGray;
  background: $erieBlack;
  margin: 0;
  color: $white;
  text-decoration: none;
  font-size: 16px;
  padding: 14px;
  width: 100%;
  height: 48px;
  display: block;
  box-sizing: border-box;
  text-align: center;
}
