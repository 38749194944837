$loading-spinner-size: 36px;

.spinner {
  animation: spinner 1.2s linear infinite;
  border-style: solid;
  border-radius: 50%;
  display: block;

  &.inButton {
    border-color: #fff #fff #fff transparent;
    border-width: 2px;
    position: absolute;
    height: $loading-spinner-size;
    width: $loading-spinner-size;
  }

  &.inOverlay {
    border-color: #999 #999 #999 transparent;
    border-radius: 50%;
    border-width: 3px;
    height: $loading-spinner-size;
    width: $loading-spinner-size;
  }
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
