@import '~@ri-digital/web-style-guide/index.scss';

.underlined-link {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding-top: 16px;
  text-decoration: none;
  color: $black;

  &::after {
    content: '';
    display: block;
    width: 100%;
    margin-top: 2px;
    height: 1px;
    background: $black;
  }
}

.underlined-link.small {
  font-size: 12px;
  padding-top: 0;
  padding-left: 5px;
}
